@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,600;1,400;1,600&display=swap');

html,
body,
label {
  font-family: 'Roboto', sans-serif;
  z-index: 0;
}

.Select {
  width: 444px !important;
  margin-right: 5px;
  z-index: 0 !important;
}

.Select.is-open {
  z-index: 1 !important;
}

.Select-menu {
  position: fixed !important;
  width: 440px !important;
  margin: 1px;
}

@media print {
  html {}
}

@layer base {

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
}


@layer utilities {
  .container-w-h {
    min-height: 90%;
    max-height: 90%;
  }

  .custom-circle {
    @apply bg-blue-900;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

  .logo-heading {
    @apply text-sm md:text-xl text-white font-bold;
  }

  .page-heading {
    @apply bg-gray-700 text-white;
  }

  .nav-bar-bg {
    @apply bg-gray-700;
  }

  .nav-item {
    @apply text-white text-sm;
  }

  .logo-footer {
    @apply text-sm text-white bg-gray-700 font-bold;
  }

  .nav-dropdown-bg {
    @apply bg-white hover:bg-gray-200 text-xs text-black;
  }

  .bg-theme {
    @apply bg-white;
  }

  .heading {
    @apply text-lg text-white font-bold;
  }

  .sub-heading {
    @apply text-xs text-gray-600 font-bold;
  }


  .frame {
    @apply border-2;
  }

  .data {
    @apply text-xs;
  }

  .stick-bg {
    @apply bg-gray-100;
  }

  .input-field {
    @apply text-sm;
  }

  .input-textArea-field {
    @apply text-black h-10 w-5/6;
  }

  .table-header {
    @apply text-black text-sm bg-gray-100 border-2;
  }

  .table-row {
    @apply even:bg-gray-50 odd:bg-gray-300 text-black text-xs;
  }

  .table-data-input {
    @apply bg-transparent focus:bg-yellow-200;
  }

  .table-data {
    @apply border-x border-y border-gray-400 cursor-pointer;
  }

  .sub-navbar {
    @apply border rounded bg-gray-700 font-bold p-1 text-sm
  }

  .sub-navbar-active {
    @apply bg-blue-900 text-white rounded p-1 text-sm
  }

  .button-border {
    @apply border rounded border-gray-400;
  }

  .pushable {
    border-radius: 12px;
    cursor: pointer;
  }

  .front {
    border-radius: 12px;
    transform: translateY(-6px);
  }

  .pushable:active .front {
    transform: translateY(-2px);
  }
}


.select-all-on-touch {
  -webkit-user-select: all;
  user-select: all;
}

.print {
  border: none;
  margin-top: 2rem;
}

.bor th {
  border: none;
  margin-top: 2rem;

}

.bor td {
  border: none;
  margin-top: 2rem;

}

@media print {
  .no-print {
    display: none;
  }
}


.notification-icon {
  position: relative;
  display: inline-block;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 10px;
}


/* Toast.css */
.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 1em;
  border-radius: 5px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeInOut 3s;
}

.toast-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toast button {
  margin-left: 10px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

@keyframes fadeInOut {

  0%,
  100% {
    opacity: 0;
  }

  10%,
  90% {
    opacity: 1;
  }
}


.backgroundColor {
  background-color: rgb(78, 20, 61);
  /* background: linear-gradient(to bottom right, rgba(153, 34, 117, 0.678)); */

}

.leedForm {
  position: relative;
  top: -2rem;
  display: flex;
  justify-content: flex-end;
  right: 15%;
}